import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { ItemsList } from "./utils"

const SectorsList = ({ sectors }) => (
  <CustomItemsList>
    {sectors.map(sector => (
      <li key={sector.id}>
        <div></div>
        <img src={sector.image.url} alt={sector.title} />
        <p>{sector.title}</p>
      </li>
    ))}
  </CustomItemsList>
)

const CustomItemsList = styled(ItemsList)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: ${DesignTokens.spacing[8]};

  & > li {
    width: 100%;
    margin: 0 0 ${DesignTokens.spacing[4]};
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    div {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: ${DesignTokens.overlay.image};
    }
    p {
      color: ${DesignTokens.colors.white};
      font-size: ${DesignTokens.fontSizes[6]};
      text-transform: uppercase;
      position: absolute;
      bottom: 2rem;
      left: 2rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (min-width: 769px) {
      margin: 2% 2% 0 0;
      width: calc(98% / 2);
      &:nth-child(-n + 2) {
        margin-top: 0;
      }
      &:nth-child(odd) {
        margin-right: 2%;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    @media screen and (min-width: 1025px) {
      width: calc(96% / 3);
      &:nth-child(-n + 3) {
        margin-top: 0;
      }
      &:nth-child(even) {
        margin-right: 2%;
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
`

export default SectorsList
